import {
  useState,
  useEffect,
  createContext,
  FC,
  Dispatch,
  SetStateAction,
  ReactNode,
} from 'react'
import { useRouter } from 'next/router'
import {
  getLocaleRegionIdFromPath,
  sampleRate,
  usePrevious,
} from '@aether/utils'
import {
  createCartClient,
  equalAttributes,
} from '@aether/services/shopify-service'
import { useCustomerContext } from '@aether/account/utils-customer-context'
import { cartAnalytics } from './helpers/cartAnalytics'
import { captureException, withScope } from '@sentry/nextjs'
import { ReturnsState, useReturns } from './useReturns'
import { GtmSendP2RCartData } from '@aether/services/gtm-service'
import {
  ShopifyMutationCartLinesAddArgs,
  ShopifyMutationCartLinesUpdateArgs,
  ShopifyResolvedCart,
  ShopifyResolvedCartLine,
} from '@aether/shopify-sdk'

export type CartContextType = {
  cartId: string | null
  cart: ShopifyResolvedCart | null
  processing: boolean
  quantity: number
  isCartModalOpen: boolean
  isCartUpsellModalOpen: boolean
  addCartLines: (
    lines: ShopifyMutationCartLinesAddArgs['lines'],
  ) => Promise<ShopifyResolvedCartLine[] | undefined>
  removeCartLines: (lines: ShopifyResolvedCartLine[]) => void
  updateCartLines: (lines: ShopifyMutationCartLinesUpdateArgs['lines']) => void
  setCartModalOpen: Dispatch<SetStateAction<boolean>>
  setCartUpsellModalOpen: Dispatch<SetStateAction<boolean>>
  handleReturnsCheckout: () => Promise<void>
  isReturnsMode: boolean
  returnsState: ReturnsState | null
}

export const CartContext = createContext<CartContextType>({
  cartId: null,
  cart: null,
  processing: false,
  quantity: 0,
  isCartModalOpen: false,
  isCartUpsellModalOpen: false,
  setCartModalOpen: () => null,
  addCartLines: () => undefined,
  removeCartLines: () => undefined,
  updateCartLines: () => undefined,
  handleReturnsCheckout: () => Promise.resolve(),
  setCartUpsellModalOpen: () => null,
  isReturnsMode: false,
  returnsState: null,
})

const createLsCartIdService = (regionId: string) => {
  const LOCAL_STORAGE_CART_ID_KEY = `cartId_${regionId}`
  return {
    get: () => localStorage.getItem(LOCAL_STORAGE_CART_ID_KEY),
    set: (id: string) => localStorage.setItem(LOCAL_STORAGE_CART_ID_KEY, id),
    remove: () => localStorage.removeItem(LOCAL_STORAGE_CART_ID_KEY),
  }
}

export const CartProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const { locale } = useRouter()
  const [cart, setCart] = useState<ShopifyResolvedCart>(null)
  const [linesQuantity, setLinesQuantity] = useState<number>(0)
  const [processing, setProcessing] = useState(false)

  const regionId = getLocaleRegionIdFromPath(locale)[0]
  const lsCartId = createLsCartIdService(regionId)
  const cartClient = createCartClient({ locale, cartId: cart?.id || null })

  const debugCondition = process.env['CONTEXT'] !== 'production'

  const logDebugMessage = (...args: any[]) =>
    debugCondition && console.log(...args)

  const errorDebugMessage = (...args: any[]) =>
    debugCondition && console.error(...args)

  const [isCartModalOpen, setCartModalOpen] = useState<boolean>(false)
  const [isCartUpsellModalOpen, setCartUpsellModalOpen] =
    useState<boolean>(false)

  const [isBuyerUpdated, setIsBuyerUpdated] = useState<boolean>(false)
  const [requestCartDestroy, setRequestCartDestroy] = useState<boolean>(false)
  const { returnsState, isReturnsMode, handleReturnsCheckout } = useReturns({
    cart,
    destroyCart: () => setRequestCartDestroy(true),
  })

  const { accessToken, isLoggedIn, customer } = useCustomerContext()
  const prevIsLoggedIn = usePrevious<boolean>(isLoggedIn)

  useEffect(() => {
    if (cart) {
      GtmSendP2RCartData(cart?.cost.subtotalAmount.amount, cart?.lines || [])
    }
  }, [cart])

  const __fetchOrCreateCart = async () => {
    setProcessing(true)
    const LSCartId = lsCartId.get()

    try {
      const cartRes = LSCartId
        ? await cartClient.fetch(LSCartId)
        : await cartClient.create()
      __resolveCartOperation(cartRes)
    } catch (e) {
      // we catch cartClient errors of fetchOrCreate without passing res to resolver
      // when function hits the retry limit we show the toast
      // toast(
      //   `We couldn't prepare the cart for You. Please refresh the browser and try again.`,
      // )
      const err = new Error(
        `[fetchOrCreateCart] Reached retry limit. ${JSON.stringify(e)}`,
      )
      errorDebugMessage(err)
      captureException(err)
      return
    }
  }

  const __resolveCartOperation = (cartRes: ShopifyResolvedCart) => {
    if (cartRes) {
      // proper cart
      logDebugMessage('[refreshCart] FRESH CART RECEIVED. updating state...')
      setCart(cartRes)
      setLinesQuantity(cartRes.totalQuantity)
      setProcessing(false)
      lsCartId.set(cartRes.id)
    }
    if (!cartRes) {
      // empty cart
      logDebugMessage('[refreshCart] EMPTY CART RECEIVED. recreating...')
      setCart(null)
      setLinesQuantity(0)
      lsCartId.remove()
      __fetchOrCreateCart()
    }
  }

  const __revalidateCart = async (
    fn: () => Promise<ShopifyResolvedCartLine[] | void> | void,
  ) => {
    setProcessing(true)
    if (!lsCartId.get() || !cart) {
      await __fetchOrCreateCart()
      await fn()
    }
  }

  const __reloadCart = async () => {
    await __fetchOrCreateCart()
  }

  const addCartLines: CartContextType['addCartLines'] = async (newLines) => {
    logDebugMessage('[addCartLines] ADD LINES')
    await __revalidateCart(() => addCartLines(newLines))
    const res = await cartClient.addLines(newLines)

    if (res) {
      const updatedCart = res
      const addedLines = newLines.reduce(
        (acc: ShopifyResolvedCartLine[], line) => {
          const matchedLine = updatedCart?.lines?.find((l) => {
            return (
              l.merchandise.id === line.merchandiseId &&
              equalAttributes(l.attributes, line.attributes)
            )
          })
          return matchedLine ? [...acc, matchedLine] : acc
        },
        [],
      )

      __resolveCartOperation(res)

      if (addedLines.length === newLines.length) {
        cartAnalytics.addCartLine(newLines, updatedCart)
        return addedLines
      } else {
        const err = new Error(
          `[addCartLines] Added lines not found in incoming cart, lines: ${JSON.stringify(
            newLines,
          )}, cart: ${JSON.stringify(updatedCart)}`,
        )
        withScope(function (scope) {
          scope.setTag('sampleRate', sampleRate.CART_PROVIDER)
          scope.setTag('cart:', 'addCartLines')
          captureException(err)
        })
        return undefined
      }
    }
    __resolveCartOperation(res)
    return undefined
  }

  const removeCartLines: CartContextType['removeCartLines'] = async (lines) => {
    try {
      logDebugMessage('[removeCartLines] REMOVE LINES')
      await __revalidateCart(() => removeCartLines(lines))
      const lineIds: string[] = lines.map((line) => line.id)
      const res = await cartClient.removeLines(lineIds)
      cartAnalytics.removeCartLine(lines)
      __resolveCartOperation(res)
    } catch (e) {
      withScope(function (scope) {
        scope.setTag('sampleRate', sampleRate.CART_PROVIDER)
        scope.setTag('cart:', 'removeCartLines')
        captureException(e)
      })
    }
  }

  const updateCartLines: CartContextType['updateCartLines'] = async (lines) => {
    try {
      logDebugMessage('[updateCartLines] UPDATE LINES')
      await __revalidateCart(() => updateCartLines(lines))
      const res = await cartClient.updateLines(lines)
      cartAnalytics.updateCartLine(lines, cart)
      __resolveCartOperation(res)
    } catch (e) {
      withScope(function (scope) {
        scope.setTag('sampleRate', sampleRate.CART_PROVIDER)
        scope.setTag('cart:', 'updateCartLines')
        captureException(e)
      })
    }
  }

  const updateBuyerIdentity = async (
    customerAccessToken?: string,
    email?: string,
  ) => {
    try {
      logDebugMessage('[updateBuyerIdentity] UPDATE BUYER IDENTITY')
      await __revalidateCart(() =>
        updateBuyerIdentity(customerAccessToken, email),
      )
      const res = await cartClient.updateBuyer({ customerAccessToken, email })
      __resolveCartOperation(res)
    } catch (e) {
      withScope(function (scope) {
        scope.setTag('sampleRate', sampleRate.CART_PROVIDER)
        scope.setTag('cart:', 'updateBuyerCart')
        captureException(e)
      })
    }
  }

  //refresh cart on focus
  useEffect(() => {
    __reloadCart()
    window.addEventListener('focus', __reloadCart)
    return () => window.removeEventListener('focus', __reloadCart)
  }, [locale, requestCartDestroy])

  useEffect(() => {
    // update cart with buyer identity on login
    if (
      isLoggedIn &&
      cart &&
      customer?.email &&
      accessToken &&
      !isBuyerUpdated
    ) {
      updateBuyerIdentity(accessToken, customer.email)
      setIsBuyerUpdated(true)
    }

    // destroy cart on logout
    if (prevIsLoggedIn && !isLoggedIn) {
      setRequestCartDestroy(true)
      setIsBuyerUpdated(false)
    }
  }, [cart, customer, accessToken, isLoggedIn])

  return (
    <CartContext.Provider
      value={{
        cartId: cart?.id || null,
        cart,
        processing,
        addCartLines,
        isCartModalOpen,
        isCartUpsellModalOpen,
        setCartModalOpen,
        setCartUpsellModalOpen,
        removeCartLines,
        updateCartLines,
        quantity: linesQuantity,
        returnsState,
        isReturnsMode,
        handleReturnsCheckout,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}
